<template>
  <div class="top">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>

      <el-breadcrumb-item
        v-for="(item, index) in list"
        :key="index"
        :to="{ path: item.path, query: item.query }"
      >
        {{ item.name }}
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="route_div">
      <span class="route_type" v-if="routeDetail.route_type == 1"
        >散拼线路</span>
      <span class="route_type" v-if="routeDetail.route_type == 2"
        >定制线路</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BreadCrumb',
  props: { 
    list: { type: Array, required: true },
    routeDetail: { type: Object }
  },
};
</script>

<style lang="scss" scoped>
.top {
  display: flex;
  align-items: flex-end;
  margin: 30px 0;
  :deep(.el-breadcrumb) {
    height: 17px;
    line-height: 17px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
  }
  .route_div {
    margin-left: 10px;
  }
  .route_type {
    color: #fff;
    background: red;
    padding: 3px 7px;
    font-size: 12px;
    border-radius: 5px;
  }
}
</style>