<template>
  <div class="body-main">
    <div class="main">
      <div>
        <bread-crumb :list="breadCrumbList" :routeDetail="routeDetail"></bread-crumb>
      </div>
      <div class="main-1">
        <div class="left">
          <!-- <el-image :src="src" class="main-img">
            <div slot="placeholder" class="image-slot">
              加载中
              <span class="dot">...</span>
            </div>
          </el-image>
          <el-image
            style="cursor: pointer"
            v-for="(item, index) in subImgList"
            :src="`${$imgBaseURL}${item.b_img}`"
            :key="index"
            class="sub-img"
            @click="clickImageItem(`${$imgBaseURL}${item.b_img}`)"
          >
            <div slot="placeholder" class="image-slot">
              加载中
              <span class="dot">...</span>
            </div>
          </el-image> -->
          <div>
            <swiper
              :options="swiperOptionTop"
              class="swiper gallery-top"
              ref="swiperTop"
            >
              <swiper-slide
                class="swiper-slide"
                v-for="(item, index) in subImgList"
                :key="index"
              >
                <el-image
                  :src="`${$imgBaseURL}${item.b_img}`"
                  :fit="fit_val"
                  class="main-img"
                ></el-image>
              </swiper-slide>
              <div
                class="swiper-button-next swiper-button-white"
                slot="button-next"
              ></div>
              <div
                class="swiper-button-prev swiper-button-white"
                slot="button-prev"
              ></div>
            </swiper>
            <!-- swiper2 Thumbs -->
            <swiper
              :options="swiperOptionThumbs"
              class="swiper gallery-thumbs"
              ref="swiperThumbs"
            >
              <swiper-slide
                class="swiper-slide"
                v-for="(item, index) in subImgList"
                :key="index"
              >
                <el-image
                  :src="`${$imgBaseURL}${item.b_img}`"
                  :fit="fit_val"
                ></el-image>
              </swiper-slide>
            </swiper>
          </div>
        </div>

        <div class="right">
          <div class="title">
            {{ routeDetail.route_nm }} 编号：{{ routeDetail.route_bn }}
          </div>
          <div class="box-1">
            <div v-if="routeDetail.route_type == 1">
              价格：
              <span class="price">{{ routeDetail.price }}</span> 起/人
            </div>

            <div v-if="routeDetail.route_type == 1">请填写详情</div>
            <div v-if="routeDetail.route_type == 2">
              请在【预定行程】中填写表单
            </div>
            <div class="box-1-button" @click="collectionRoute">
              <i class="icon-star"></i>
              <span class="text">收藏</span>
            </div>
          </div>
          <div class="box-2">
            <span class="box-2-item" v-if="routeDetail.route_type == 1">
              参团城市
              <span class="blank"></span>
              {{ routeDetail.start_city_nm }}
            </span>
            <span class="box-2-item" v-if="routeDetail.route_type == 1">
              离团城市
              <span class="blank"></span>
              {{ routeDetail.end_city_nm }}
            </span>

            <span class="box-2-item" v-if="routeDetail.route_type == 2">
              入境城市
              <span class="blank"></span>
              {{ routeDetail.entry_city_nm }}
            </span>

            <span class="box-2-item" v-if="routeDetail.route_type == 2">
              离境城市
              <span class="blank"></span>
              {{ routeDetail.departure_city_nm }}
            </span>

            <span class="box-2-item" v-if="routeDetail.route_type == 2">
              最佳时节
              <span class="blank"></span>
              {{ routeDetail.route_season_nm }}
            </span>

            <span class="box-2-item" v-if="routeDetail.route_type == 2">
              日均里程
              <span class="blank"></span>
              {{ routeDetail.daily_mileage_nm }}
            </span>

            <span class="box-2-item">
              行程天数
              <span class="blank"></span>
              {{ routeDetail.travel_days }} 天
            </span>
            <span class="box-2-item">
              参团方式
              <span class="blank"></span>
              {{ routeDetail.join_type_nm }}
            </span>
            <span class="box-2-item">
              服务语言
              <span class="blank"></span>
              {{ routeDetail.service_language_nm }}
            </span>
          </div>
        </div>
      </div>
      <!-- 定制线路订单-->
      <div v-if="routeDetail.is_closed == 1" class="main-2">
        <div class="left">
          <el-calendar>
            <template v-slot:dateCell="{ date, data }">
              <div class="date_box" @click="dateCheckFun(date, data)">
                <p class="calendar-date">
                  {{ parseInt(data.day.split('-').slice(2).join()) }}
                </p>
                <p
                  :class="[
                    'calendar-price',
                    getDate(date, data) ? 'can-clickable' : 'can-not-clickable',
                  ]"
                >
                  {{ getDate(date, data) }}
                </p>
              </div>
            </template>
          </el-calendar>
          <div class="box-1">
            <div class="box-1-title">不同房型人均价格说明</div>
            <div class="box-1-item">
              <div class="line-1">
                <div class="line-1-1"></div>
                <div class="line-1-2">或</div>
                <div class="line-1-1"></div>
                <div class="line-1-1"></div>
              </div>
              <div class="line-2">单人一房</div>
              <div class="line-3">
                {{ getPrice(routePriceList.race_single) }}
              </div>
            </div>
            <div class="box-1-item">
              <div class="line-1">
                <div class="line-1-1"></div>
                <div class="line-1-2">或</div>
                <div class="line-1-1"></div>
                <div class="line-1-1"></div>
              </div>
              <div class="line-2">双人一房</div>
              <div class="line-3">{{ getPrice(routePriceList.race_two) }}</div>
            </div>
            <div class="box-1-item">
              <div class="line-1">
                <div class="line-1-1"></div>
                <div class="line-1-1"></div>
              </div>
              <div class="line-2">三人一房</div>
              <div class="line-3">
                {{ getPrice(routePriceList.race_three) }}
              </div>
            </div>
            <div class="box-1-item">
              <div class="line-1">
                <div class="line-1-1"></div>
                <div class="line-1-1"></div>
              </div>
              <div class="line-2">四人一房</div>
              <div class="line-3">{{ getPrice(routePriceList.race_four) }}</div>
            </div>
            <div class="box-1-item">
              <div class="line-1">
                <div class="line-1-3"></div>
              </div>
              <div class="line-2">儿童</div>
              <div class="line-3">
                {{ getPrice(routePriceList.race_children) }}
              </div>
            </div>
            <div class="box-1-item">
              <div class="line-1">
                <div class="line-1-4"></div>
              </div>
              <div class="line-2">配房</div>
              <div class="line-3">
                {{ getPrice(routePriceList.race_free_make) }}
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="title">选择出游日期和人数</div>
          <div class="line-1">
            <div class="line-1-left">出行日期</div>
            <span class="line-1-input">{{ formatCalendarDate }}</span
            >您还未选择出行日期！
          </div>
          <div class="line-2">
            <div class="line-2-left">人员入住</div>
            <div class="line-2-right">
              <div
                class="line-2-right-box-1"
                v-for="(elem, index) in roomArr"
                :key="index"
              >
                <div>房间{{ index + 1 }}</div>
                <el-select
                  v-model="elem.adultValue"
                  placeholder="0成人"
                  :disabled="!calendarValue"
                  class="line-2-right-select"
                >
                  <el-option
                    v-for="item in adultOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <el-select
                  v-model="elem.childValue"
                  placeholder="0儿童"
                  :disabled="!calendarValue"
                  class="line-2-right-select"
                >
                  <el-option
                    v-for="item in childOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <div class="delete" v-if="index > 0" @click="deleteRoom(index)">
                  删除
                </div>
              </div>
              <div class="add-one" @click="addOneRoom">+添加一间房</div>
            </div>
          </div>
          <div class="line-3">
            <div class="line-3-left">基础总价</div>
            <div class="line-3-right">
              <span class="price">{{ getAllPrice() }}</span>
              （人均{{ averagePrice }}，其他收费服务请在订购时勾选）
            </div>
          </div>
          <div class="line-4" @click="order()">我要订购</div>
        </div>
      </div>
      <!-- 预定线路订单-->
      <div v-if="routeDetail.is_closed == 0" class="main-3">
        <div class="left">
          <p>该精彩行程已售罄</p>
          <p>您可以提交预定申请</p>
          <p>当有新的出团计划，我们会与你取得联系</p>
        </div>
        <div class="right">
          <el-form
            :rules="rules"
            ref="madeLine"
            class="demo-ruleForm"
            :model="madeLine"
            label-width="120px"
          >
            <div class="name">
              <el-form-item label="您的姓:" prop="customer_name_f">
                <el-input
                  type="text"
                  size="small"
                  v-model="madeLine.customer_name_f"
                  placeholder
                ></el-input>
              </el-form-item>

              <el-form-item label="您的名:" prop="customer_name_l">
                <el-input
                  type="text"
                  size="small"
                  v-model="madeLine.customer_name_l"
                  placeholder
                ></el-input>
              </el-form-item>
            </div>

            <div class="phone">
              <el-form-item label="区号:" prop="customer_country_code">
                <el-select
                  size="small"
                  v-model="madeLine.customer_country_code"
                  placeholder="区域"
                >
                  <el-option
                    v-for="(item, index) in article"
                    :key="index"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="联系电话:" prop="customer_tel">
                <el-input
                  type="text"
                  size="small"
                  v-model="madeLine.customer_tel"
                  placeholder
                ></el-input>
              </el-form-item>
            </div>

            <el-form-item label="电子邮箱:" prop="customer_email">
              <el-input
                type="text"
                size="small"
                v-model="madeLine.customer_email"
                placeholder
              ></el-input>
            </el-form-item>

            <el-form-item label="期望入团时间:" prop="beg_date">
              <el-date-picker
                size="small"
                placeholder
                v-model="madeLine.beg_date"
                style="width: 100%"
                :picker-options="option"
              ></el-date-picker>
            </el-form-item>

            <el-form-item label="参团成人数:" prop="travel_num">
              <el-input
                type="number"
                size="small"
                v-model="madeLine.travel_num"
                placeholder
              ></el-input>
            </el-form-item>

            <div class="number">
              <el-form-item label="儿童数不占床:" prop="children_s_num">
                <el-input
                  type="number"
                  size="small"
                  v-model="madeLine.children_s_num"
                  placeholder
                ></el-input>
              </el-form-item>

              <el-form-item label="儿童数占床:" prop="children_b_num">
                <el-input
                  type="number"
                  size="small"
                  v-model="madeLine.children_b_num"
                  placeholder
                ></el-input>
              </el-form-item>
            </div>

            <el-form-item label="微信:" prop="customer_wx">
              <el-input
                type="text"
                size="small"
                v-model="madeLine.customer_wx"
                placeholder
              ></el-input>
            </el-form-item>

            <el-form-item label="个性化需求:" prop="personal_demand">
              <el-input
                type="textarea"
                size="small"
                v-model="madeLine.personal_demand"
                placeholder
              ></el-input>
            </el-form-item>

            <el-form-item label="备注:" prop="customer_demo">
              <el-input
                type="textarea"
                size="small"
                v-model="madeLine.customer_demo"
                placeholder
              ></el-input>
            </el-form-item>
            <br />

            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="submitForm('madeLine')"
                >立即创建</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>

      <el-tabs
        type="border-card"
        class="tabs-wrap"
        v-model="activeTabName1"
        @tab-click="clickSubTab"
      >
        <el-tab-pane
          :label="item.type_nm"
          v-for="item in routeDetailDictList"
          :key="item.id"
          :name="item.id"
        >
          <el-tabs
            type="border-card"
            class="sub-tabs-wrap"
            v-model="activeTabName2"
            @tab-click="clickSubTab"
          >
            <el-tab-pane
              :label="elem.type_nm"
              v-for="(elem, index) in item.sub_type"
              :key="elem + index"
              :name="elem.id"
            ></el-tab-pane>
          </el-tabs>
        </el-tab-pane>
      </el-tabs>
      <div
        v-for="(elem, j) in routeDetailDictList"
        :key="j"
        :id="elem.id"
        ref="qwe"
        class="asd"
      >
        <div
          class="box-3"
          v-for="(item, index) in elem.sub_type"
          :key="index"
          :id="item.id"
          ref="dfd"
        >
          <div class="box-3-title">{{ item.type_nm }}</div>
          <div
            class="box-3-content ql-editor"
            v-for="item in routeIntroductionDetail(item)"
            :key="item.id"
          >
            <div
              v-if="
                routeDetail.route_type == 2 &&
                item.type_id === 'route_detail_type_141'
              "
            >
              <!-- 简单页面开始 -->
              <div class="custom-trave-title">您的行程</div>
              <div class="custom-trave-sub-title">
                {{ routeDetail.route_nm }}
              </div>
              <el-form
                ref="customForm"
                :model="customForm"
                :rules="customRules"
              >
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-radio v-model="customForm.order_type" label="1"
                      >我要计算此线路价格</el-radio
                    >
                    <div class="custom-trave-desc">
                      选择出发日期与人数，只需几步，就可以获得该路线的费用总价。填写联系表格，专人将与您联系，确认出发日期可行性。
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <el-radio v-model="customForm.order_type" label="2"
                      >我要个性化订制</el-radio
                    >
                    <div class="custom-trave-desc">
                      您希望修改旅行时长，升级酒店级别，或有更多需求，选择"我要个性化定制”，填写定制表，专属行程策划师会在此路线基础上为您设计一份专属路线!
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="成人人数" prop="travel_num">
                      <!-- <el-select
                      v-model="customForm.travel_num"
                      placeholder="请选择"
                      style="width: 100%;"
                    >
                      <el-option v-for="item in 10" :key="item" :label="item" :value="item"></el-option>
                      </el-select>-->
                      <el-input v-model="customForm.travel_num"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item
                      label="儿童人数（占床）"
                      prop="children_b_num"
                    >
                      <!-- <el-select
                      v-model="customForm.children_b_num"
                      placeholder="请选择"
                      style="width: 100%;"
                    >
                      <el-option v-for="item in 5" :key="item" :label="item" :value="item"></el-option>
                      </el-select>-->
                      <el-input v-model="customForm.children_b_num"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item
                      label="儿童人数（不占床）"
                      prop="children_s_num"
                    >
                      <!-- <el-select
                      v-model="customForm.children_s_num"
                      placeholder="请选择"
                      style="width: 100%;"
                    >
                      <el-option v-for="item in 5" :key="item" :label="item" :value="item"></el-option>
                      </el-select>-->

                      <el-input v-model="customForm.children_s_num"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="出发日期" prop="beg_date">
                      <el-date-picker
                        type="date"
                        placeholder="点击选择出发日期"
                        v-model="customForm.beg_date"
                        style="width: 100%"
                        :picker-options="option"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12" v-if="customForm.order_type == 1">
                    <el-form-item label="行程天数" prop="travel_days">
                      <el-input v-model="customForm.travel_days"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12" v-if="customForm.order_type == 2">
                    <el-form-item label="出行结束日期" prop="end_date">
                      <el-date-picker
                        type="date"
                        placeholder="点击出行结束日期"
                        v-model="customForm.end_date"
                        style="width: 100%"
                        :picker-options="option"
                      ></el-date-picker>
                      <!-- <el-input v-model="customForm.end_date"></el-input> -->
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20" v-if="customForm.order_type == 2">
                  <el-col :span="12">
                    <el-form-item label="您的预算" prop="budget">
                      <el-input v-model="customForm.budget"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="外汇币种" prop="currency">
                      <el-select
                        v-model="customForm.currency"
                        placeholder="请选择"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in currencyList"
                          :key="item.currency_id"
                          :label="item.currency_nm"
                          :value="item.currency_id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                      label="单房差（单人大床房）"
                      prop="single_room_diff"
                    >
                      <el-select
                        v-model="customForm.single_room_diff"
                        placeholder="请选择"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in single_room_diff_array"
                          :key="item"
                          :label="item"
                          :value="item"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="个人签证办理" prop="personal_visa">
                      <el-input v-model="customForm.personal_visa"></el-input>
                      <!-- <el-select
                      v-model="customForm.personal_visa"
                      placeholder="请选择"
                      style="width: 100%;"
                    >
                      <el-option v-for="item in 5" :key="item" :label="item" :value="item"></el-option>
                      </el-select>-->
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- <div class="custom-trave-price">总价（人民币，不包含国际机票）：</div> -->
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="机票">
                      <el-input
                        type="textarea"
                        placeholder="告诉我们您的出发城市，以及对舱位等级的需求，我们将为您提供最佳的机票方案。"
                        v-model="customForm.ticket_demand"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="你有什么特殊需求?">
                      <el-input
                        type="textarea"
                        placeholder="请告诉我们您的特殊需求，我们会尽最大能力去满足。完成预订后也可随时提出特殊请求哦。"
                        v-model="customForm.personal_demand"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <div class="custom-trave-title">您的联系方式</div>
                <el-row :gutter="20">
                  <el-col :span="24">
                    <el-form-item prop="customer_sex">
                      <el-radio v-model="customForm.customer_sex" label="女"
                        >女生</el-radio
                      >
                      <el-radio v-model="customForm.customer_sex" label="男"
                        >先生</el-radio
                      >
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="姓" prop="customer_name_f">
                      <el-input v-model="customForm.customer_name_f"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="国家" prop="customer_country_code">
                      <el-select
                        v-model="customForm.customer_country_code"
                        placeholder="区域"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="(item, index) in article"
                          :key="index"
                          :label="item"
                          :value="item"
                        ></el-option>
                      </el-select>

                      <!-- <el-input v-model="customForm.customer_country_code"></el-input> -->
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="名" prop="customer_name_l">
                      <el-input v-model="customForm.customer_name_l"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="电话" prop="customer_tel">
                      <el-input v-model="customForm.customer_tel"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="微信">
                      <el-input v-model="customForm.customer_wx"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="电子邮件" prop="customer_email">
                      <el-input v-model="customForm.customer_email"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="城市" prop="customer_city">
                      <el-input v-model="customForm.customer_city"></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- <el-col :span="12">
                  <el-form-item label="您是任何认识艾格蓝宝的？">
                    <el-input v-model="customForm.customer_demo"></el-input>
                  </el-form-item>
                  </el-col>-->
                </el-row>
                <div class="custom-trave-price">
                  总价（人民币，不包含国际机票）：详细价格请咨询
                </div>
                <el-form-item class="te-al-ce">
                  <el-button type="primary" @click="onSubmit('customForm')"
                    >立即创建</el-button
                  >
                </el-form-item>
              </el-form>
              <!-- 简单页面结束 -->
            </div>
            <div v-else v-html="item.detail_val"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import article from '@/utils/article';
import BreadCrumb from '@/components/BreadCrumb';
import moment from 'moment';
import { mapState } from 'vuex';
import {
  travelRouteImg,
  routeDetailDict,
  routeDetailByRouteId,
  routePrice,
  travelRouteDate,
  routeDetailSearchType,
  userCollectionRouteInsert,
  routePriceByRouteBn,
  travel_route_reserve_insert,
  currency,
  cus_route_order_insert, // 增加定制线路订单
  travel_route_reserve_search_by, // 查询预定线路信息
  cus_route_order_search_by, // 查询定制线路信息
  travel_route_reserve_edit, // 编辑预定线路
  cus_route_order_edit, // 编辑定制线路
} from '@/api/index';

export default {
  sw_name: 'swiper-example-thumbs-gallery',
  sw_title: 'Thumbs gallery with Two-way control',
  name: 'travelRouteDetail',
  components: {
    BreadCrumb,
  },
  data() {
    return {
      fit_val: 'fill',
      single_room_diff_array: [],
      currencyList: [],
      customForm: {
        order_type: '1',
        travel_num: '',
        children_b_num: '0',
        children_s_num: '0',
        beg_date: '',
        travel_days: '',
        end_date: '',
        budget: '',
        currency: '2',
        single_room_diff: '',
        personal_visa: '',
        ticket_demand: '',
        personal_demand: '',
        customer_name_f: '',
        customer_name_l: '',
        customer_sex: '',
        customer_country_code: '',
        customer_tel: '',
        customer_email: '',
        customer_wx: '',
        customer_city: '',
        customer_demo: '',
      },
      option: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000;
        },
      },
      customRules: {
        // order_type: [
        //   { required: true, message: '请输入活动名称', trigger: 'blur' },
        // ],
        travel_num: [
          { required: true, message: '请输入成人人数', trigger: 'blur' },
        ],
        children_b_num: [
          { required: true, message: '请输入儿童数占床', trigger: 'blur' },
        ],
        children_s_num: [
          { required: true, message: '请输入儿童数不占床', trigger: 'blur' },
        ],
        beg_date: [
          { required: true, message: '请输入出行开始日期', trigger: 'blur' },
        ],
        travel_days: [
          { required: true, message: '请输入出行天数', trigger: 'blur' },
        ],
        end_date: [
          { required: true, message: '请输入出行结束日期', trigger: 'blur' },
        ],
        budget: [{ required: true, message: '请输入预算', trigger: 'blur' }],
        currency: [{ required: true, message: '请输入币种', trigger: 'blur' }],
        single_room_diff: [
          { required: true, message: '请选择单房差', trigger: 'blur' },
        ],
        personal_visa: [
          { required: true, message: '请输入个人签证办理', trigger: 'blur' },
        ],
        ticket_demand: [
          { required: true, message: '请输入机票备注', trigger: 'blur' },
        ],
        // personal_demand: [
        //   { required: true, message: '请输入个性化需求', trigger: 'blur' },
        // ],
        customer_name_f: [
          { required: true, message: '请输入姓', trigger: 'blur' },
        ],
        customer_name_l: [
          { required: true, message: '请输入名', trigger: 'blur' },
        ],
        customer_sex: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        customer_country_code: [
          { required: true, message: '请选择国家区号', trigger: 'blur' },
        ],
        customer_tel: [
          { required: true, message: '请输入电话', trigger: 'blur' },
        ],
        customer_email: [
          { required: true, message: '请输入email', trigger: 'blur' },
        ],
        customer_wx: [
          { required: true, message: '请输入微信', trigger: 'blur' },
        ],
        customer_city: [
          { required: true, message: '请输入城市', trigger: 'blur' },
        ],
        // customer_demo: [
        //   { required: true, message: '请输入备注', trigger: 'blur' },
        // ],
      },
      article: article,
      breadCrumbList: [],
      route_bn: this.$route.query.route_bn,
      src: '',
      calendarValue: '',
      madeLine: {
        route_id: '',
        reserve_bn: '',
        beg_date: '',
        travel_num: '2',
        children_b_num: '0',
        children_s_num: '0',
        personal_demand: '',
        customer_name_f: '',
        customer_name_l: '',
        customer_country_code: '',
        customer_tel: '',
        customer_email: '',
        customer_demo: '',
        submit_time: new Date().toISOString(),
        bill_status: '1',
        pay_status: '0',
      },
      averagePrice: 0, // 平均价
      routeDetail: {},
      adultOptions: [],
      childOptions: [],
      rules: {
        beg_date: [
          { required: true, message: '请输入出行开始日期', trigger: 'blur' },
        ],
        travel_num: [
          { required: true, message: '请输入成人人数', trigger: 'blur' },
        ],
        // children_b_num: [
        //   { required: true, message: '请输入儿童数占床', trigger: 'blur' },
        // ],
        // children_s_num: [
        //   { required: true, message: '请输入儿童数不占床', trigger: 'blur' },
        // ],
        // personal_demand: [
        //   { required: true, message: '请输入个性化需求', trigger: 'blur' },
        // ],
        customer_name_f: [
          { required: true, message: '请输入姓', trigger: 'blur' },
        ],
        customer_name_l: [
          { required: true, message: '请输入名', trigger: 'blur' },
        ],
        customer_country_code: [
          { required: true, message: '请输入国家区号', trigger: 'blur' },
        ],
        customer_tel: [
          { required: true, message: '请输入电话', trigger: 'blur' },
        ],
        customer_email: [
          { required: true, message: '请输入email', trigger: 'blur' },
        ],
        customer_wx: [
          { required: true, message: '请输入微信', trigger: 'blur' },
        ],
        // customer_demo: [
        //   { required: true, message: '请输入备注', trigger: 'blur' },
        // ],
      },
      roomArr: [
        {
          adultValue: '',
          childValue: '',
        },
      ],
      subImgList: [],
      routeDetailDictList: [],
      routeDetailList: [],
      routePriceList: {
        race_single: 0,
        race_two: 0,
        race_three: 0,
        race_four: 0,
        race_children: 0,
        race_free_make: 0,
      },
      routeDateList: [],
      offsetTopArr1: [],
      resArr1: [],
      activeTabName1: '',
      offsetTopArr2: [],
      resArr2: [],
      activeTabName2: '',
      count: 0,
      priceFlag: [],
      price: 0,
      single_room_diff: '',
      swiperOptionThumbs: {
        spaceBetween: 10,
        // 设置slider容器能够同时显示的slides数量(carousel模式)。
        slidesPerView: 4,
        // slidesPerView: 'auto',
        loop: true,
        freeMode: true,
        loopedSlides: 5, // looped slides should be the same
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        // autoplay: {
        //   dely: 3000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false,
        // },
        // 激活的图片在中间，默认在左侧
        // centeredSlides: true,
        touchRatio: 0.2,
        slideToClickedSlide: true,
      },
      swiperOptionTop: {
        spaceBetween: 10,
        // slidesPerView: 4,
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        autoplay: {
          dely: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      },

      // swiperOptionThumbs: {
      //   spaceBetween: 10,
      //   slidesPerView: 'auto',
      //   loop: true,
      //   loopedSlides: 5, // looped slides should be the same
      //   centeredSlides: true,
      //   touchRatio: 0.2,
      //   slideToClickedSlide: true
      // },
      // swiperOptionTop: {
      //     spaceBetween: 10,
      //     slidesPerView: 4,
      //     loop: true,
      //     freeMode: true,
      //     loopedSlides: 5, // looped slides should be the same

      //     navigation: {
      //       nextEl: '.swiper-button-next',
      //       prevEl: '.swiper-button-prev'
      //     }
      //   },
    };
  },
  created() {
    console.log(this.routeDetail)
    this.getCurrency();
    this.travelRouteDetailFunc();

    if (this.$route.query.type == 1) {
      // 查询预定线路信息
      this.get_travel_route_reserve_search_by();
    }

    if (this.$route.query.type == 2) {
      // 查询订制线路信息
      this.get_cus_route_order_search_by();
    }
  },
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
      window.addEventListener('scroll', this.scrollFunc);
    }, 500);
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.$swiper;
      const swiperThumbs = this.$refs.swiperThumbs.$swiper;
      swiperTop.controller.control = swiperThumbs;
      swiperThumbs.controller.control = swiperTop;
    });
  },
  watch: {
    'customForm.travel_num': {
      handler: function (value) {
        this.customForm.single_room_diff = '';
        this.single_room_diff_array = [];
        if (value) {
          let remainder = Math.ceil(value / 2) - Math.floor(value / 2);
          for (remainder; remainder <= value; remainder += 2) {
            this.single_room_diff_array.push(remainder);
          }

          this.customForm.single_room_diff = this.single_room_diff;
        }
      },
    },
    routeDetailList() {
      setTimeout(() => {
        this.$refs.qwe.map(
          (item, index) => (this.offsetTopArr1[index] = item.offsetTop - 80)
        );
        this.$refs.dfd.map(
          (item, index) => (this.offsetTopArr2[index] = item.offsetTop - 166)
        );
      }, 1000);
    },
    currency() {
      this.routePriceFunc();
      this.travelRouteDetailFunc();
    },
  },
  computed: {
    ...mapState(['config', 'home']),
    currency() {
      return this.$store.state.home.currency.currency_id;
    },
    formatCalendarDate: function () {
      if (!this.calendarValue) return '点左侧日历选择时间';
      return moment(this.calendarValue).format('YYYY-MM-DD');
    },
    joinType() {
      let tempArr =
        this.config.baseDicJoinTypeList.filter(
          (item) => item.base_dic_code == this.routeDetail.join_type
        ) || [];
      let tempObj = { ...tempArr[0] };
      return tempObj.base_dic_name;
    },
    serviceLanguage() {
      let tempArr =
        this.config.baseDicServiceLanguageList.filter(
          (item) => item.base_dic_code == this.routeDetail.service_language
        ) || [];
      let tempObj = { ...tempArr[0] };
      return tempObj.base_dic_name;
    },
    getPrice() {
      return function (val) {
        if (val) {
          let price = this.routePriceList.currency_sign + String(val) + '人';
          return price;
        } else {
          return '不提供';
        }
      };
    },
    getDate() {
      return function (date, data) {
        if (this.routeDateList) {
          let week = date;
          if (this.routeDateList.regular_type == 1) {
            if (this.routeDateList.date_val.indexOf(data.day) != -1) {
              data.isSelected = true;
              if (this.routePriceList.race_two) {
                return (
                  this.routePriceList.currency_sign +
                  this.routePriceList.race_two
                );
              }
            }
          }
          if (this.routeDateList.regular_type == 2) {
            let flag = false;
            this.routeDateList.week_val.split('/').forEach((item, index) => {
              if (index + 1 == week.getDay() && item == 'Y') {
                flag = true;
              }
              if (week.getDay() == 0 && item == 'Y' && index == 6) {
                flag = true;
              }
            });

            if (flag) {
              data.isSelected = true;
              if (this.routePriceList.race_two) {
                // console.log(
                //   22,
                //   this.routePriceList.currency_sign +
                //     this.routePriceList.race_two
                // )
                return (
                  this.routePriceList.currency_sign +
                  this.routePriceList.race_two
                );
              }
            }
          }
          if (this.routeDateList.regular_type == 3) {
            data.isSelected = true;
            return (
              this.routePriceList.currency_sign + this.routePriceList.race_two
            );
          }
        }
      };
    },
    getAllPrice() {
      return function () {
        let priceOne = 0;
        let priceTwo = 0;
        let persion = 0;

        this.roomArr.forEach((item) => {
          if (item.adultValue) {
            persion += Number(item.adultValue);
          }

          if (item.childValue) {
            persion += Number(item.childValue);
          }

          let adult = 0;
          let child = 0;

          if (item.adultValue != '') {
            // 查询出有几个房间
            adult = Number(item.adultValue);
            if (item.childValue != '') {
              // 当前房间几儿童
              child = Number(item.childValue);
            }
            let index = adult + child - 1;
            if (
              this.priceFlag[index] &&
              this.priceFlag[index] != '不提供' &&
              index <= 3
            ) {
              if (adult) {
                let num = adult + child;
                item.type = num + '人一房';
                priceOne += this.priceFlag[index] * (adult + child);
              }
            } else {
              if (adult) {
                item.type = adult + '人一房';
                priceTwo += this.priceFlag[adult - 1] * adult;
              }
              if (child) {
                priceTwo += this.priceFlag[4] * child;
              }
            }
          }
        });
        let price = priceOne + priceTwo;
        if (price) {
          this.price = price;
          this.averagePrice =
            Math.floor(price / persion) + this.routePriceList.currency_sign;
          return this.routePriceList.currency_sign + String(price);
        }
      };
    },
  },
  methods: {
    get_travel_route_reserve_search_by() {
      let data = {
        pay_status: '',
        bill_status: '',
        route_beg_date_b: '',
        route_beg_date_e: '',
        page: 1,
        per_page: 20,
        id: [this.$route.query.id],
      };
      travel_route_reserve_search_by(data).then((res) => {
        this.madeLine = res.data[0];
      });
    },
    get_cus_route_order_search_by() {
      let data = {
        pay_status: '',
        bill_status: '',
        route_beg_date_b: '',
        route_beg_date_e: '',
        page: 1,
        per_page: 20,
        id: [this.$route.query.id],
      };
      cus_route_order_search_by(data).then((res) => {
        this.customForm = res.data[0];
        this.single_room_diff = res.data[0].single_room_diff;
      });
    },

    getCurrency() {
      currency().then((res) => {
        this.currencyList = res.data;
      });
    },
    // 定制线路订单
    onSubmit(form) {
      this.$refs[form][0].validate((valid) => {
        let that = this;
        if (valid) {
          this.customForm.route_id = this.routeDetail.id;
          this.customForm.beg_date = new Date(this.customForm.beg_date);
          this.customForm.end_date = new Date(this.customForm.end_date);

          if (this.customForm.order_type == 2) {
            let startDate = Date.parse(this.customForm.beg_date);
            let endDate = Date.parse(this.customForm.end_date);
            this.customForm.travel_days =
              (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
          }
          // 设置用户id
          this.customForm.cur_user_id = this.home.userInfo.uid;
          if (this.$route.query.id) {
            this.customForm.id = this.$route.query.id;

            cus_route_order_edit(this.customForm).then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: '定制线路编辑成功',
                  type: 'success',
                });
              }
            });
          } else {
            this.customForm.bill_date = new Date().toISOString();
            cus_route_order_insert(this.customForm).then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: '定制线路提交成功',
                  type: 'success',
                });
                setTimeout(function () {
                  that.$router.push({ path: '/' });
                }, 3000);
              }
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 预定线路订单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        let that = this;
        if (valid) {
          this.madeLine.route_id = this.routeDetail.id;
          this.madeLine.beg_date = new Date(this.madeLine.beg_date);
          this.madeLine.reserve_bn = this.$route.query.route_bn;
          // 设置用户id
          this.madeLine.cur_user_id = this.home.userInfo.uid;
          if (this.$route.query.id) {
            this.madeLine.id = this.$route.query.id;
            travel_route_reserve_edit(this.madeLine).then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: '预定线路编辑成功',
                  type: 'success',
                });
              }
            });
          } else {
            travel_route_reserve_insert(this.madeLine).then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: '预定订单提交成功',
                  type: 'success',
                });
                setTimeout(function () {
                  that.$router.push({ path: '/' });
                }, 3000);
              }
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    order() {
      let roomJson = {
        date: this.calendarValue,
        price: this.price,
        offered_info: this.roomArr,
        route_bn: this.$route.query.route_bn,
        route_id: this.routeDetail.id,
        currency_sign: this.routePriceList.currency_sign,
      };

      window.localStorage.setItem('roomJson', JSON.stringify(roomJson));
      this.$router.push({
        name: 'travelOrderList',
      });
    },
    dateCheckFun(date, data) {
      if (date > new Date()) {
        if (data.isSelected) {
          this.calendarValue = data.day;
        }
      }
    },
    // 数据获取
    async travelRouteDetailFunc() {
      // Promise.all([
      // 请求线路详细
      let response = [];
      (response[0] = await routePriceByRouteBn({
        route_bn: this.$route.query.route_bn,
        currency: this.currency,
      })),
        // 请求线路Tab栏标题
        (response[1] = await routeDetailDict({
          route_id: response[0].data[0].route_type,
        })),
        console.log(`response`, response);
      // ]).then((response) => {
      console.log(`response[0].data[0]`, response[0].data[0])
      this.routeDetail = response[0].data[0];
      this.src = `${this.$imgBaseURL}${this.routeDetail.img}`;
      // 请求线路详细图片
      this.travelRouteImgFunc();
      // 请求线路价格
      this.routePriceFunc();
      // 请求线路日期
      this.travelRouteDateFunc();
      // this.routeDetail = response[0].data[0]
      // this.src = `${this.$imgBaseURL}${this.routeDetail.img}`
      this.breadCrumbList = [
        {
          name: this.routeDetail.region_nm,
          path: '/travel-route/list',
          query: '',
        },
        {
          name: this.routeDetail.country_nm,
          path: '/travel-route/list',
          query: '',
        },
        {
          name: this.routeDetail.route_bn,
          path: '',
          query: '',
        },
      ];
      // 下面tab栏标题
      this.routeDetailDictList = response[1].data;
      this.activeTabName1 = this.routeDetailDictList[0].id;
      this.routeDetailDictList.map((item) => {
        this.resArr1.push(item.id);
        item.sub_type.map((elem) => {
          this.resArr2.push(elem.id);
          this.routeDetailSearchTypeFunc({ type_id: elem.id });
        });
      });
    },
    // 获取sub img
    travelRouteImgFunc() {
      travelRouteImg({
        route_id: this.routeDetail.id,
      }).then((response) => {
        this.subImgList = response.data;
      });
    },
    // 获取线路价格
    routePriceFunc() {
      routePrice({
        route_id: this.routeDetail.id,
        currency: Number(this.currency),
      }).then((response) => {
        let array = [];
        if (response.calc_items_adult.length > 0) {
          response.calc_items_adult.forEach((item) => {
            let a = {};
            for (let key in item) {
              a.value = key;
              a.label = item[key];
            }
            array.push(a);
          });
        }

        this.adultOptions = array;

        let array2 = [];

        if (response.calc_items_child.length > 0) {
          response.calc_items_child.forEach((item) => {
            let a = {};
            for (let key in item) {
              a.value = key;
              a.label = item[key];
            }
            array2.push(a);
          });
        }

        this.childOptions = array2;

        if (response.data.length > 0) {
          this.routePriceList = response.data[0];

          if (response.data[0].race_single) {
            this.priceFlag.push(response.data[0].race_single);
          } else {
            this.priceFlag.push(false);
          }

          if (response.data[0].race_two) {
            this.priceFlag.push(response.data[0].race_two);
          } else {
            this.priceFlag.push(false);
          }

          if (response.data[0].race_three) {
            this.priceFlag.push(response.data[0].race_three);
          } else {
            this.priceFlag.push(false);
          }

          if (response.data[0].race_four) {
            this.priceFlag.push(response.data[0].race_four);
          } else {
            this.priceFlag.push(false);
          }

          if (response.data[0].race_children) {
            this.priceFlag.push(response.data[0].race_children);
          } else {
            this.priceFlag.push(false);
          }
        }
      });
    },
    // 获取线路日期
    travelRouteDateFunc() {
      travelRouteDate({
        route_id: this.routeDetail.id,
      }).then((response) => {
        this.routeDateList = response.data;
      });
    },
    // 根据线路详情type_id获取全部线路富文本
    // routeDetailByRouteIdFunc() {
    //   routeDetailByRouteId({
    //     route_id: this.routeDetail.id,
    //   }).then((response) => {
    //     this.routeDetailList = response.data
    //   })
    // },
    // 根据线路详情type_id逐条获取全部线路富文本
    routeDetailSearchTypeFunc({ type_id }) {
      routeDetailSearchType({
        route_id: this.routeDetail.id,
        type_id: type_id,
      }).then((response) => {
        this.routeDetailList = [
          ...this.routeDetailList,
          Object.assign({}, { type_id }, ...response.data),
        ];
        // console.log(`this.routeDetailList`, this.routeDetailList)
      });
    },
    // 根据线路详情type_id获取指定ID线路富文本
    routeIntroductionDetail({ id }) {
      let tempArr =
        this.routeDetailList.filter((item) => item.type_id === id) || [];
      return tempArr;
    },
    // 滚动触发的方法
    scrollFunc() {
      let scrolltopTemp =
        document.documentElement.scrollTop || document.body.scrollTop;
      // if (scrolltopTemp < this.offsetTopArr1[0]) return;
      let val1 = this.binarySearch(
        this.offsetTopArr1,
        scrolltopTemp + 86,
        0,
        this.offsetTopArr1.length - 1
      );
      this.activeTabName1 = this.resArr1[val1] || '';
      let val2 = this.binarySearch(
        this.offsetTopArr2,
        scrolltopTemp + 86,
        0,
        this.offsetTopArr2.length - 1
      );
      this.activeTabName2 = this.resArr2[val2] || '';
    },
    // 点击sub Tab
    clickSubTab({ name }) {
      setTimeout(() => {
        let temp = document.getElementById(name).offsetTop;
        document.documentElement.scrollTop = temp - 86;
      }, 200);
    },
    // 点击sub img
    clickImageItem(src) {
      this.src = src;
    },
    // 增加一个room
    addOneRoom() {
      this.roomArr.push({
        adultValue: '',
        childValue: '',
      });
    },
    // 删除room
    deleteRoom(index) {
      this.roomArr.splice(index, 1);
    },
    // 滚动加载
    load() {
      this.count++;
      this.routeDetailSearchTypeFunc({ type_id: this.resArr2[this.count] });
    },
    // 二分法查找
    binarySearch(arr, findVal, leftIndex, rightIndex) {
      if (leftIndex > rightIndex) {
        var find = leftIndex - 1;
        return find;
      }
      var midIndex = Math.floor((leftIndex + rightIndex) / 2);
      var midVal = arr[midIndex];
      if (midVal > findVal) {
        return this.binarySearch(arr, findVal, leftIndex, midIndex - 1);
      } else if (midVal < findVal) {
        return this.binarySearch(arr, findVal, midIndex + 1, rightIndex);
      } else {
        let find = midIndex + 1;
        return find;
      }
    },
    // 线路收藏
    collectionRoute() {
      userCollectionRouteInsert({
        route_id: this.routeDetail.id,
        user_id: this.user_id || '63d7a3a6c1044b0f870df3efe03db487',
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: '收藏成功',
            type: 'success',
          });
        }
      });
    },
    // 定制线路
  },
};
</script>

<style lang="scss" scoped>
.body-main {
  // ::v-deep.ql-align-center{
  //   text-align: center;
  // }
  .main {
    position: relative;
  }
  .custom-trave-sub-title {
    text-align: center;
    line-height: 50px;
    margin-bottom: 10px;
    font-size: 22px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 500px;
    display: block;
    margin: 20px auto;
  }

  width: 100%;
  display: flex;
  background: rgba(245, 245, 245, 1);
  justify-content: center;
  .main {
    width: 1200px;
    .main-1 {
      margin-bottom: 20px;
      display: flex;
      padding: 20px;
      background: rgba(255, 255, 255, 1);
      .left {
        width: 560px;
        font-size: 0;
        .main-img {
          width: 100%;
          height: 337px;
          background: rgba(255, 255, 255, 1);
          margin-bottom: 10px;
        }
        .sub-img {
          width: calc(20% - 6.4px);
          margin-right: 8px;
          height: 65px;
          &:last-child {
            margin-right: 0px;
          }
        }
        .sub-img-sel {
          width: 100%;
          margin-right: 8px;
          height: 65px;
          &:last-child {
            margin-right: 0px;
          }
        }

      }
      .right {
        flex: 1;
        margin-left: 30px;
        display: flex;
        margin-left: 30px;
        flex-direction: column;
        justify-content: flex-start;
        .title {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          line-height: 24px;
        }
      }
      .box-1 {
        margin: 20px 0;
        padding: 0 15px;
        width: 561px;
        height: 72px;
        background: rgba(245, 245, 245, 1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .price {
          font-size: 26px;
          color: #ff0000;
        }
        .box-1-button {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          .icon-star {
            width: 30px;
            height: 30px;
            background: url('../../assets/index/icon-001.png') no-repeat;
            background-size: contain;
          }
          width: 111px;
          height: 42px;
          background: rgba(255, 111, 46, 1);
          border-radius: 4px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
        }
      }
      .box-2 {
        font-size: 0;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 24px;
        &-item {
          display: inline-block;
          vertical-align: middle;
          width: 50%;
          font-size: 16px;
          .blank {
            display: inline-block;
            width: 20px;
          }
        }
      }
    }
  }
  .main-3 {
    background: rgba(255, 255, 255, 1);
    margin-bottom: 20px;
    padding: 20px;
    display: flex;

    .left {
      text-align: right;
      width: 560px;
      padding-top: 130px;
      padding-right: 130px;

      p:nth-child(1) {
        font-size: 30px;
      }

      p:nth-child(2) {
        padding: 20px 0;
        font-size: 30px;
      }
    }

    .right {
      .phone,
      .name,
      .number {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      :deep(.el-form-item) {
        margin-bottom: 15px;
      }
      :deep(.el-input--small) {
        margin-top: 5px;
      }
    }
  }
  .main-2 {
    background: rgba(255, 255, 255, 1);
    margin-bottom: 20px;
    padding: 20px;
    display: flex;
    .left {
      width: 560px;
      border: 1px solid rgba(237, 237, 237, 1);
      .date_box {
        width: 100%;
        height: 100%;
      }
      :deep(.el-calendar__header) {
        padding-top: 8px;
        padding-bottom: 8px;
      }
      :deep(.el-calendar__body) {
        padding: 0;
        th {
          padding: 3px 0;
        }
        .el-calendar-table {
          .el-calendar-day {
            height: 46px;
            .calendar-date {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: rgba(102, 102, 102, 1);
            }
            .calendar-price {
              text-align: center;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: rgba(255, 0, 0, 1);
            }
          }
          tr td:first-child {
            border-left: none;
          }
          tr td:last-child {
            border-right: none;
          }
        }
        .el-calendar-table:not(.is-range) td.next .el-calendar-day,
        .el-calendar-table:not(.is-range) td.prev .el-calendar-day {
          visibility: hidden;
        }
      }

      .box-1 {
        .box-1-title {
          text-align: center;
          width: 100%;
          height: 32px;
          line-height: 31px;
          border-bottom: 1px solid rgba(237, 237, 237, 1);
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
        }
        .box-1-item {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          vertical-align: middle;
          width: calc(100% / 6);
          height: 92px;
          border-right: 1px solid rgba(237, 237, 237, 1);
          &:last-child {
            border: none;
          }
          .line-1 {
            display: flex;

            .line-1-1 {
              display: inline-block;
              width: 24px;
              height: 24px;
              background: url('../../assets/index/icon-002.png') no-repeat;
              background-size: contain;
            }
            .line-1-3 {
              display: inline-block;
              width: 24px;
              height: 24px;
              background: url('../../assets/index/icon-003.png') no-repeat;
              background-size: contain;
            }
            .line-1-4 {
              display: inline-block;
              width: 24px;
              height: 24px;
              background: url('../../assets/index/icon-004.png') no-repeat;
              background-size: contain;
            }
          }
          .line-2 {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgba(102, 102, 102, 1);
            line-height: 24px;
          }
          .line-3 {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgba(255, 0, 0, 1);
            line-height: 24px;
          }
        }
      }
    }
    .right {
      flex: 1;
      margin-left: 30px;
      .title {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        padding-bottom: 15px;
        border-bottom: 2px solid rgba(245, 245, 245, 1);
      }
      .line-1 {
        margin: 20px 0 14px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 24px;
        display: flex;
        align-items: center;
        &-left {
          margin-right: 14px;
        }
        &-input {
          width: 179px;
          height: 30px;
          border: 1px solid rgba(153, 153, 153, 1);
          border-radius: 4px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
          line-height: 28px;
          padding-left: 10px;
          padding-right: 10px;
          margin-right: 13px;
        }
      }
      .line-2 {
        display: flex;
        align-items: baseline;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 24px;
        &-left {
          margin-right: 14px;
        }
        &-right {
          .line-2-right-box-1 {
            display: flex;
            align-items: center;
            .delete {
              margin-left: 10px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: rgba(61, 126, 255, 1);
              cursor: pointer;
            }
          }
          .add-one {
            cursor: pointer;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgba(61, 126, 255, 1);
            line-height: 24px;
          }
          &-select {
            margin: 7px;
            :deep(.el-input__inner) {
              padding-left: 5px;
              width: 82px;
              height: 29px;
              background: rgba(255, 255, 255, 1);
              border: 1px solid rgba(153, 153, 153, 1);
              border-radius: 4px;
            }
            :deep(.el-input__icon) {
              line-height: 20px;
            }
          }
        }
      }
      .line-3 {
        margin: 14px 0 0;
        padding-bottom: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 24px;
        display: flex;
        align-items: center;
        border-bottom: 2px solid #f5f5f5;
        &-left {
          margin-right: 14px;
        }
        &-right {
          .price {
            font-size: 16px;
            color: #ff0000;
          }
        }
      }
      .line-4 {
        cursor: pointer;
        margin: 48px 0 20px;
        width: 200px;
        height: 43px;
        background: rgba(255, 111, 46, 1);
        border-radius: 8px;
        font-size: 19px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 43px;
        text-align: center;
      }
    }
  }
  .tabs-wrap {
    position: sticky;
    top: 60px;
    margin-bottom: 20px;
    border: none;
    box-shadow: none;
    :deep(.el-tabs__header) {
      padding-left: 28px;
      height: 43px;
      background: rgba(111, 15, 149, 1);
      border-bottom: none;
      .el-tabs__item {
        border: none;
        margin-top: 0;
        height: 43px;
        line-height: 43px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(254, 254, 254, 1);
        &.is-active {
          color: rgba(111, 15, 149, 1);
        }
      }
    }

    :deep(.el-tabs__content) {
      padding: 0;
    }
    .sub-tabs-wrap {
      border: none;
      box-shadow: none;
      :deep(.el-tabs__header) {
        height: 43px;
        padding: 10px 0 8px;
        background: rgba(255, 255, 255, 1);
        padding-left: 0px;
        .el-tabs__item {
          padding-left: 34px !important;
          padding-right: 34px;
          height: 25px;
          line-height: 25px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          border-right: 1px solid rgba(200, 198, 198, 1) !important;
          &:last-child {
            border-right: none;
          }
          &.is-active {
            color: rgba(111, 15, 149, 1);
          }
        }
      }
    }
    :deep(.el-tabs--border-card
      > .el-tabs__header
      .el-tabs__item
      + .el-tabs__item,
    .el-tabs--border-card > .el-tabs__header .el-tabs__item:first-child) {
      margin-left: 0;
    }
  }
  .box-3 {
    margin-bottom: 20px;
    width: 1200px;
    background: rgba(255, 255, 255, 1);
    &-title {
      text-align: center;
      margin-left: 26px;
      width: 120px;
      height: 48px;
      background: rgba(111, 15, 149, 1);
      border-radius: 0px 0px 10px 10px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height: 48px;
    }
    &-content {
      padding: 25px;
      // ::v-deep img {
      //   display: block;
      //   margin: 0 auto;
      // }
    }
  }
  .custom-trave {
    color: #333;
    :deep(.el-form-item__label) {
      font-size: 16px;
      font-weight: 600;
    }
    :deep(.el-radio__label) {
      font-size: 16px;
    }
    :deep(.el-radio__input.is-checked .el-radio__inner) {
      border-color: rgba(111, 15, 149, 1);
      background: rgba(111, 15, 149, 1);
    }
    :deep(.el-radio__input.is-checked + .el-radio__label) {
      color: rgba(111, 15, 149, 1);
    }
    .te-al-ce {
      text-align: center;
    }
    &-title {
      text-align: center;
      font-size: 16px;
      line-height: 35px;
      border-bottom: 1px solid #ccc;
      border-top: 1px solid #ccc;
      margin-top: 80px;
      &:first-child {
        border-top: none;
        margin-top: 0px;
      }
    }
    &-sub-title {
      text-align: center;
      line-height: 50px;
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 22px;
      position: relative;
      &::after {
        position: absolute;
        left: 50%;
        bottom: 0;
        margin-left: -20px;
        content: '';
        width: 40px;
        height: 1px;
        background-color: #ccc;
      }
    }
    &-price {
      background: #000;
      color: #fff;
      line-height: 35px;
      padding: 0px 10px;
      margin-bottom: 20px;
    }
    &-desc {
      padding: 20px 0 10px;
    }
  }
  :deep(.el-tabs--border-card) {
    z-index: 99;
  }

  .swiper-container {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  // .swiper-container {
  //   background-color: #000;
  // }
  .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  .gallery-top {
    height: 80%;
    width: 100%;
  }
  .gallery-thumbs {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
  }
  .gallery-thumbs .swiper-slide {
    width: 25%;
    // height: 100%;
    height: 65px;
    opacity: 0.4;
  }
  .gallery-thumbs .swiper-slide-active {
    opacity: 1;
  }

}
</style>